<template>
  <modal
    :title="title"
    name="modal-feature-list"
    :has-apply="true"
    :has-delete="canBeDeleted"
    :delete-label="$t('modal.featureList.delete.label')"
    :delete-tooltip="$t('modal.featureList.delete.tooltip')"
    :is-updating="isUpdating"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-input
        class="modal-feature-list__input"
        v-model.trim="modelData.tag"
        :label="$t('modal.featureList.label.name')"
        id="feature-tag"
        :error="$v.modelData.tag.$error"
      >
        <template v-if="$v.modelData.tag.$error && !$v.modelData.tag.required">
          {{ $t('errors.required') }}
        </template>
      </ui-input>
      <ui-input
        class="modal-feature-list__input"
        v-model.trim="modelData.featureKey"
        :label="$t('modal.featureList.label.featureKey')"
        id="feature-key"
        :error="$v.modelData.featureKey.$error"
      >
        <template v-if="$v.modelData.featureKey.$error && !$v.modelData.featureKey.required">
          {{ $t('errors.required') }}
        </template>
      </ui-input>
      <ui-tag
        class="modal-feature-list__input"
        v-model="modelData.list"
        :placeholder="$t('modal.featureList.label.placeholder')"
        :tag-label="$t('modal.featureList.label.tag')"
        :show-label="true"
        @tag="addTag"
        @input="setTags"
      />
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiTag from '@/components/UI/Tag.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ModalFeatureList',
  components: {
    UiInput,
    UiTag,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        tag: '',
        featureKey: '',
        list: [],
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      canBeDeleted: false,
    }
  },
  mounted() {
    this.modelData = Object.assign(
      {
        tag: '',
        featureKey: '',
        list: [],
      },
      this.data
    )
    if (this.data && this.data.id) {
      this.canBeDeleted = true
    }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.modelData.id) {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'update',
          })
        } else {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'create',
          })
        }
        this.$v.$reset()
      }
    },
    remove() {
      this.$emit('save', {
        objKey: this.modelData,
        status: 'delete',
      })
    },
    closed() {
      this.$emit('closed')
    },
    addTag(tag) {
      this.modelData.list.push(tag)
    },
    setTags(tags) {
      this.modelData.list = tags
    },
  },
  validations() {
    return {
      modelData: {
        tag: {
          required,
        },
        featureKey: {
          required,
        },
        list: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-feature-list {
  &__description,
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }
}
</style>
