<template>
  <screen-grid class="client-general">
    <screen-card
      :title="$t('clients.id.general.section.generalData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.name')"
          icon="article"
          :has-data="typeof currentClient.name === 'string'"
          :data="currentClient.name"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editSimpleField('name', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.locationTypes')"
          icon="attribut"
          :has-data="Array.isArray(currentClient.locationTypes) && currentClient.locationTypes.length > 0"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editList('locationTypes', 'text', 'list')"
        >
          <template v-slot:data>
            <span v-for="(type, idx) in currentClient.locationTypes" :key="`location-type-${idx}`">
              {{ type }}<template v-if="idx < currentClient.locationTypes.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.gatoreviewsId')"
          icon="article"
          :has-data="typeof currentClient.gatoreviewsId === 'string'"
          :data="currentClient.gatoreviewsId"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editSimpleField('gatoreviewsId', 'text', 'input')"
        />
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.s3Path')"
          icon="link"
          :has-data="typeof currentClient.s3Path === 'string'"
          :data="currentClient.s3Path"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
        />
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.language')"
          icon="language"
          :has-data="typeof currentClient.defaultLocaleId === 'number'"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editLocales"
        >
          <template v-slot:data>
            <div>
              {{ $t('clients.id.general.section.generalData.label.defaultLanguage') }}
              {{ mapLocale(locales, currentClient.defaultLocaleId).localeName }}
            </div>
            <div>
              {{ $t('clients.id.general.section.generalData.label.otherLanguage') }}
              <span v-for="(locale, idx) in currentClient.locales" :key="`locales-${idx}`">
                {{ mapLocale(locales, locale).localeName
                }}<template v-if="idx < currentClient.locales.length - 1">, </template>
              </span>
              <span v-if="currentClient.locales.length === 0">
                {{ $t('clients.id.general.section.generalData.label.noData') }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.logo')"
          icon="photo"
          :has-data="typeof currentClient.logo === 'string'"
          :data="currentClient.logo"
          ratio="1-3"
          :is-image="true"
          :is-loading="isLoading"
          @click="editLogo('logo', currentClient.logo)"
        />
        <screen-block
          :title="$t('clients.id.algolia.section.algoliaConfiguration.label.customRules')"
          icon="category"
          :has-data="typeof currentClient.customRules === 'object' && currentClient.customRules !== null"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('customRules', 'json', 'textarea')"
        >
          <template v-slot:data>
            <code>
              {{ currentClient.customRules }}
            </code>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <feature-list-card
      :isLoading="isLoading"
      :featureList="currentClientFeatureList"
      :isEditable="true"
      @editFeature="editFeature"
    />
  </screen-grid>
</template>

<script>
import { isUserAuthorized } from '@/config/permissions.config'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import FeatureListCard from '@/components/FeatureList/FeatureListCard.vue'
import mapLocaleMixin from '@/mixins/mapLocale.mixin'

export default {
  name: 'ClientGeneral',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    FeatureListCard,
  },
  mixins: [mapLocaleMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    currentClientFeatureList: {
      type: Array,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isUserAuthorized,
    }
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
    editList(objKey, type, mode, listKey = null) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          listKey,
        },
      })
    },
    editLocales() {
      this.$emit('editLocales', {
        objKey: 'locales',
        defaultLocale: this.currentClient.defaultLocaleId,
        otherLocales: this.currentClient.locales,
        locales: this.locales,
      })
    },
    editLogo(type, media) {
      this.$emit('editMedia', {
        media: {
          id: -1,
          picture: media,
        },
        parameters: {
          type,
          noLink: true,
        },
      })
    },
    editFeature(feature) {
      this.$emit('editFeature', {
        feature: feature || { clientId: this.currentClient.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.client-general {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .client-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .client-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__state {
      margin-top: 2px;

      .client-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .client-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__toggle {
      margin-left: $gutter-mobile;
    }
  }

  &__opening-hours {
    &__slot {
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter-mobile / 2;

      &:last-child {
        margin-bottom: 0;
      }

      &__day {
        font-weight: 500;
      }

      &__time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>
