<template>
  <screen-grid class="product-locator">
    <screen-card ratio="1-1" display="grid" :is-loading="isLoading">
      <template v-slot:body>
        <screen-block
          :title="$t('clients.id.productLocator.section.productData.label.product')"
          :has-data="true"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
          :class="{
            'product-locator__status--active': activeProductLocator,
            'product-locator__status--inactive': !activeProductLocator,
          }"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="product-locator__status__icon backoffice-icons">delivery</div>
            </div>
          </template>
          <template v-slot:data>
            <div class="product-locator__status__state">
              {{
                activeProductLocator
                  ? $t('clients.id.productLocator.section.productData.label.active')
                  : $t('clients.id.productLocator.section.productData.label.inactive')
              }}
            </div>
          </template>
          <template v-slot:right>
            <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
            <ui-switch
              v-else
              class="location-general__status__toggle"
              id="active-locator"
              :standalone="true"
              :value="activeProductLocator"
              :input-value="activeProductLocator"
              :disabled="!isUserAuthorized(['sa'])"
              @input="editProductLocator"
            ></ui-switch>
          </template>
        </screen-block>
      </template>
    </screen-card>

    <screen-card
      :title="$t('clients.id.productLocator.section.title')"
      ratio="1-1"
      display="grid"
      :overflow-hidden="true"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('clients.id.productLocator.section.productData.label.authorize')"
          icon="article"
          :has-data="typeof currentClient.leadProductAuthorizeFrom === 'string'"
          :data="currentClient.leadProductAuthorizeFrom"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editSimpleField('leadProductAuthorizeFrom', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('clients.id.productLocator.section.productData.label.reply')"
          icon="mail"
          :has-data="typeof currentClient.productLocatorReplyTo === 'string'"
          :data="currentClient.productLocatorReplyTo"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editSimpleField('productLocatorReplyTo', 'email', 'input')"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { isUserAuthorized } from '@/config/permissions.config'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'

export default {
  name: 'ClientProductLocator',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    UiSwitch,
    SkeletonIcon,
    SkeletonLine,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isUserAuthorized,
    }
  },
  computed: {
    activeProductLocator() {
      return this.currentClient.productLocatorStatus === '1'
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
    editProductLocator(value) {
      const productLocatorStatus = value ? '1' : '0'
      this.$emit('updateProductLocator', productLocatorStatus)
    },
  },
}
</script>

<style lang="scss" scoped>
.product-locator {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .product-locator__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .product-locator__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__state {
      .product-locator__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .product-locator__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__toggle {
      margin-left: $gutter-mobile;
    }
  }
}
</style>
