<template>
  <screen-card
    class="feature-list-card"
    :title="$t('clients.id.general.section.featureList.title')"
    ratio="1-1"
    display="grid"
    :is-loading="isLoading"
  >
    <template v-slot:body>
      <screen-block
        v-for="(feature, idx) in featureList"
        :key="`feature-${idx}`"
        :title="getTitle(feature)"
        icon="attribute"
        :has-data="Array.isArray(feature.list) && feature.list.length > 0"
        class="feature-list-card__feature"
        ratio="1-2"
        :is-loading="isLoading"
        :is-editable="isEditable"
        @click="editFeature(feature)"
      >
        <template v-slot:data>
          <template v-for="(l, idxList) in feature.list">
            <template>
              {{ getItemIfExist(feature, l) }}<template v-if="idxList < feature.list.length - 1">, </template>
            </template>
          </template>
        </template>
      </screen-block>
      <screen-block
        v-if="featureList && featureList.length === 0"
        :title="$tc('clients.id.general.section.featureList.label.feature', 1)"
        icon="date"
        :has-data="Array.isArray(featureList) && featureList.length > 0"
        class="feature-list-card__feature"
        ratio="1-1"
        :is-loading="isLoading"
        :is-editable="isEditable"
      />
      <screen-action
        v-if="isEditable"
        :label="$t('clients.id.general.section.featureList.button.add')"
        icon="add"
        :is-loading="isLoading"
        @click="editFeature()"
      />
    </template>
  </screen-card>
</template>

<script>
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenAction from '@/components/Screen/Action.vue'
import ScreenBlock from '@/components/Screen/Block.vue'

export default {
  name: 'FeatureListCard',
  components: {
    ScreenCard,
    ScreenAction,
    ScreenBlock,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: null,
    },
    featureList: {
      type: Array,
      required: true,
    },
    featureListDetails: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    getItemIfExist(feature, i) {
      let result = i
      if (this.featureListDetails !== null) {
        this.featureListDetails[feature.tag]?.items.forEach(item => {
          if (item.key === i) result = item.title
        })
      }
      return result
    },
    getTitle(feature) {
      if (this.featureListDetails !== null) {
        return this.featureListDetails[feature.tag] ? this.featureListDetails[feature.tag].title : feature.tag
      }
      return feature.tag
    },
    editFeature(feature) {
      this.$emit('editFeature', feature)
    },
  },
}
</script>
