<template>
  <screen-grid>
    <screen-card
      :title="$t('clients.id.algolia.section.algoliaConfiguration.title')"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('clients.id.algolia.section.algoliaConfiguration.label.algoliaRules')"
          icon="category"
          :has-data="typeof currentClient.algoliaRules === 'object' && currentClient.algoliaRules !== null"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editSimpleField('algoliaRules', 'json', 'textarea')"
        >
          <template v-slot:data>
            <code>
              {{ currentClient.algoliaRules }}
            </code>
          </template>
        </screen-block>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'

export default {
  name: 'FrontOfficeContent',
  components: {
    ScreenCard,
    ScreenBlock,
    ScreenGrid,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
  },
}
</script>
