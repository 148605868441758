<template>
  <div class="locations-id" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      class="locations-id__header"
      :title="currentClient.name"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      v-if="!hasError"
    />
    <screen-error v-if="hasError" :route="{ name: 'Clients' }" :label="$t('clients.id.error.button.back')" />
    <screen-container direction="column" v-else>
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab"
        :is-loading="isLoading"
        :current-user="currentUser"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />

      <!-- General Section -->
      <client-general
        v-if="currentTab === 'general'"
        :is-loading="isLoading"
        :current-client="currentClient"
        :current-client-feature-list="currentClientFeatureList"
        :locales="locales"
        @editSimpleField="editSimpleField"
        @editMedia="editMedia"
        @editLocales="editLocales"
        @editFeature="editFeature"
      />

      <!-- Algolia Section -->
      <client-algolia
        v-if="currentTab === 'algolia'"
        :is-loading="isLoading"
        :current-client="currentClient"
        @editSimpleField="editSimpleField"
      />

      <!-- Product Locator Section -->
      <client-product-locator
        v-if="currentTab === 'productLocator'"
        :is-loading="isLoading"
        :current-client="currentClient"
        :current-client-feature-list="currentClientFeatureList"
        :locales="locales"
        @editSimpleField="editSimpleField"
        @updateProductLocator="updateProductLocator"
      />
    </screen-container>
    <modal-locales
      v-if="isEditing && editingModal === 'locales'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :label="modal.label"
      :locales="modal.locales"
      :default-locale="modal.defaultLocale"
      :other-locales="modal.otherLocales"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-media
      v-if="isEditing && editingModal === 'media'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      @save="updateMedia"
      @add="addMedia"
      @remove="removeMedia"
      @closed="closedModal"
    />
    <modal-feature-list
      v-if="isEditing && editingModal === 'feature'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="updateFeature"
      @closed="closedModal"
    />
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import ScreenError from '@/components/Screen/Error.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalMedia from '@/components/Modal/Media.vue'
import ModalLocales from '@/components/Modal/Locales.vue'
import ModalFeatureList from '@/components/Modal/FeatureList.vue'
import ClientGeneral from '@/components/Client/General.vue'
import ClientAlgolia from '@/components/Client/Algolia.vue'
import ClientProductLocator from '@/components/Client/ProductLocator.vue'
import { mediaParser } from '@/utils/media.util'

export default {
  name: 'ClientsId',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    ScreenError,
    ModalSimpleField,
    ModalMedia,
    ModalLocales,
    ModalFeatureList,
    ClientGeneral,
    ClientAlgolia,
    ClientProductLocator,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isEditing: false,
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
      hasError: false,
    }
  },
  beforeMount() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('general')
    }
  },
  mounted() {
    this.isLoading = true
    this.getClient({ clientId: this.$route.params.id })
      .then(() => {
        this.isLoading = false
      })
      .catch(() => {
        this.isLoading = false
        this.hasError = true
      })
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
      currentClientFeatureList: state => state.client.currentClientFeatureList,
      locales: state => state.user.locales,
      currentUser: state => state.user.currentUser,
    }),
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('clients.list.breadcrumb'),
          route: {
            name: 'Clients',
          },
        },
        {
          label: this.currentClient.name,
          route: {
            name: 'Client',
            params: {
              id: this.currentClient.id,
            },
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('clients.id.tab.general'),
          key: 'general',
        },
        {
          label: this.$t('clients.id.tab.algolia'),
          key: 'algolia',
          access: 'sa',
        },
        {
          label: this.$t('clients.id.tab.productLocator'),
          key: 'productLocator',
        },
      ]
    },
    modalTitle() {
      return this.$t('common.modal.title.client', {
        client: this.currentClient.name,
      })
    },
  },
  methods: {
    ...mapActions({
      getClient: 'client/getClient',
      updateClient: 'client/updateClient',
      updateFeatureList: 'client/updateFeatureList',
      uploadToolMedia: 'tool/uploadToolMedia',
      removeToolMedia: 'tool/removeToolMedia',
    }),
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    editSimpleField(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`clients.id.modal.${payload.objKey}`)
      this.modal.data = this.currentClient[payload.objKey]
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editMedia(payload) {
      this.modal.data = payload.media
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'media'
      this.$nextTick(() => {
        this.$modal.show('modal-media')
      })
    },
    editLocales(payload) {
      this.modal.label = `clients.id.modal.${payload.objKey}`
      this.modal.data = this.currentClient[payload.objKey]
      this.modal.locales = payload.locales
      this.modal.defaultLocale = payload.defaultLocale
      this.modal.otherLocales = payload.otherLocales
      this.isEditing = true
      this.editingModal = 'locales'
      this.$nextTick(() => {
        this.$modal.show('modal-locales')
      })
    },
    editFeature(payload) {
      this.modal.data = payload.feature
      this.isEditing = true
      this.editingModal = 'feature'
      this.$nextTick(() => {
        this.$modal.show('modal-feature-list')
      })
    },
    async updateProductLocator(productLocatorStatus) {
      try {
        await this.updateClient({ productLocatorStatus })
        this.notificationSuccess('client', this.currentClient.name)
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      }
    },
    saveSimpleField(params) {
      this.isUpdating = true
      this.updateClient(params.objKey)
        .then(() => {
          this.$modal.hide('modal-simple-field')
          this.closedModal()
          this.notificationSuccess('client', this.currentClient.name)
        })
        .catch(() => this.notificationError())
    },
    updateMedia(params) {
      this.isUpdating = true
      this.uploadToolMedia({
        title: params.media.title,
        image: params.image,
        picture: params.media.picture,
        type: params.type,
        name: this.currentClient.name,
      })
        .then(mediaUrl => {
          if (params.type === 'logo') {
            return this.updateClient({
              logo: mediaUrl,
            })
          }
          return this.updateClient({
            [params.type]: mediaParser(this.currentClient[params.type], params.type, 'edit', params.media, mediaUrl),
          })
        })
        .then(() => {
          this.$modal.hide('modal-media')
          this.closedModal()
          this.notificationSuccess('client', this.currentClient.name)
        })
        .catch(e => this.notificationError(e))
    },
    addMedia(params) {
      this.isUpdating = true
      this.uploadToolMedia({
        title: params.media.title,
        image: params.image,
        picture: params.media.picture,
        type: params.type,
        name: this.currentClient.name,
      })
        .then(mediaUrl => {
          const type = params.type === 'locationPictures' ? 'pictures' : params.type
          return this.updateClient({
            [type]: mediaParser(this.currentClient[type], params.type, 'add', params.media, mediaUrl),
          })
        })
        .then(() => {
          this.$modal.hide('modal-media')
          this.closedModal()
          this.notificationSuccess('client', this.currentClient.name)
        })
        .catch(e => this.notificationError(e))
    },
    removeMedia(params) {
      this.isUpdating = true
      this.removeToolMedia({
        picture: params.media.picture,
      })
        .then(() => {
          const type = params.type === 'locationPictures' ? 'pictures' : params.type
          return this.updateClient({
            [type]: mediaParser(this.currentClient[type], params.type, 'delete', params.media),
          })
        })
        .then(() => {
          this.$modal.hide('modal-media')
          this.closedModal()
          this.notificationSuccess('client', this.currentClient.name)
        })
        .catch(() => this.notificationError())
    },
    updateFeature(params) {
      this.isUpdating = true
      this.updateFeatureList({ featureList: params.objKey, status: params.status })
        .then(() => {
          this.$modal.hide('modal-feature-list')
          this.closedModal()
          this.notificationSuccess('client', this.currentClient.name)
        })
        .catch(() => this.notificationError())
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
  },
}
</script>

<style lang="scss">
.locations-id {
  &__header {
    &__cta {
      margin-left: $gutter-mobile;
    }

    &__popover {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }

      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__medias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -3px;

    @media (min-width: $screen-sm) {
      margin: -6px;
    }

    &__item {
      display: inline-flex;
      position: relative;
      margin: 3px;
      width: calc(100% / 2 - 6px);

      @media (min-width: $screen-sm) {
        margin: 6px;
        width: calc(100% / 2 - 12px);
      }

      &__img {
        display: block;
        width: 100%;
      }

      &__top {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 4px 8px;

        @media (min-width: $screen-sm) {
          padding: 8px;
        }

        &__button {
          margin: $generic-button-offset;
          color: #fff;
        }

        &__label {
          color: #fff;
        }
      }

      &__bottom {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.2);
        padding: 4px 8px;
        color: #fff;

        @media (min-width: $screen-sm) {
          padding: 8px;
        }

        &__views {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: 8px;
            font-size: 1rem;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>
